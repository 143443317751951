@import '../../../../scss/theme-bootstrap';

.split-width-image-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-#{$rdirection}: auto;
  margin-#{$ldirection}: auto;
  position: relative;
  // @todo verify if we need this mpp style here
  .rectangle_wide & {
    height: 100%;
  }
  &__column1,
  &__column2 {
    display: flex;
    align-self: stretch;
    width: 100%;
    @include breakpoint($bp--large-up) {
      width: 50%;
    }
  }
  &__column1 {
    .column-layout-standard & {
      order: 1;
      @include breakpoint($bp--large-up) {
        order: 1;
      }
    }
    .column-layout-reversed & {
      order: 1;
      @include breakpoint($bp--large-up) {
        order: 2;
      }
    }
    &-inner {
      align-self: center;
      position: relative;
      width: 100%;
      &.add-images-gutter {
        margin-bottom: 5px;
        @include breakpoint($bp--large-up) {
          @include swap_direction(margin, 0 20px 0 0);
        }
      }
    }
    // multiple images
    &--2img {
      display: flex;
      flex-direction: row;
      @include breakpoint($bp--large-up) {
        flex-direction: column;
      }
      .split-width-image-text__image-col-wrap {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        max-width: 55%;
        width: 55%;
      }
      .split-width-image-text__image--image1,
      .split-width-image-text__image--image2 {
        width: 50%;
        @include breakpoint($bp--large-up) {
          width: 100%;
        }
      }
      &.add-images-gutter {
        .split-width-image-text__image--image1 {
          margin-#{$rdirection}: 5px;
          @include breakpoint($bp--large-up) {
            @include swap_direction(margin, 0 0 20px 0);
          }
        }
      }
    }
  }
  &__column2 {
    flex-direction: column;
    flex-wrap: wrap;
    .column-layout-standard & {
      order: 2;
      @include breakpoint($bp--large-up) {
        order: 2;
      }
    }
    .column-layout-reversed & {
      order: 2;
      @include breakpoint($bp--large-up) {
        order: 1;
      }
    }
    // padd inner content for mobile only when a bg color has been added
    &--has-bg {
      padding: 20px;
      @include breakpoint($bp--large-up) {
        padding: 0;
      }
    }
    &-inner {
      display: flex;
      align-self: center;
      flex-direction: column;
      &--default {
        align-self: center;
      }
      &--top {
        align-self: start;
      }
      &--bottom {
        align-self: end;
      }
    }
  }
  // Typography defaults when no styles are selected in cms
  // order refaults plus logo variations
  &__logo {
    order: 0;
    padding-bottom: 20px;
    .logo-below-eyebrow & {
      order: 1;
    }
  }
  &__eyebrow {
    @include body-text--small-bold;
    order: 1;
    .logo-below-eyebrow & {
      order: 0;
    }
  }
  &__title {
    @include title-01;
    order: 2;
  }
  &__subtitle {
    @include sub-title;
    order: 3;
  }
  &__body {
    @include body-text;
    order: 4;
  }
  &__links-wrapper {
    display: flex;
    flex-direction: column;
    order: 5;
    padding-bottom: 20px;
    &--column {
      display: flex;
      flex-direction: column;
      .hero-block__link-gutter {
        width: 0;
      }
    }
    &--row {
      display: flex;
      flex-direction: row;
      .hero-block__link-gutter {
        height: 0;
      }
    }
  }
  &__column1-25-2-75 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 25%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 75%;
      }
    }
  }
  &__column1-75-2-25 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 75%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 25%;
      }
    }
  }
  &__column1-30-2-70 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 30%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 70%;
      }
    }
  }
  &__column1-40-2-60 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 40%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 60%;
      }
    }
  }
  &__column1-70-2-30 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 70%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 30%;
      }
    }
  }
  &__column1-60-2-40 {
    .split-width-image-text__column1 {
      @include breakpoint($bp--large-up) {
        width: 60%;
      }
    }
    .split-width-image-text__column2 {
      @include breakpoint($bp--large-up) {
        width: 40%;
      }
    }
  }
}
